<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Leave Authorizations",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.refreshTableByStatus(0);
    //this.refreshTable();
  },
  methods: {
    refreshTable() {
      const url = `${this.ROUTES.leaveApplication}/authorization/supervisor/${this.getEmployee.emp_id}`;
      this.apiGet(url, "Get Leave Applications Error").then((res) => {
        const { data, officers } = res.data;
        console.log(officers)
        data.forEach((leave, index) => {
          this.applications[index] = { sn: ++index, ...leave };
        });
          this.applications.forEach((application) => {
            officers.forEach((officer) => {
              if (
                application.leapp_id === parseFloat(officer.auth_travelapp_id)
              ) {

                let names = null;
               if(application.leapp_status === 0){
                 if(officer.auth_status === 0){
                   names = `${officer.officers.emp_first_name} ${officer.officers.emp_last_name} (${officer.officers.emp_unique_id}), `;
                   application["Officer"] += names;
                 }
                }else{
                 names = `${officer.officers.emp_first_name} ${officer.officers.emp_last_name} (${officer.officers.emp_unique_id}), `;
                 application["Officer"] = 'undefined'+names;
               }

              }
            });
          });
        this.totalRows = this.applications.length;
      });
    },
    refreshTableByStatus(status) {
      const url = `${this.ROUTES.leaveApplication}/authorization/supervisor/${this.getEmployee.emp_id}/${status}`;
      this.apiGet(url, "Get Leave Applications Error").then((res) => {
        const { data, officers } = res.data;
        data.forEach((leave, index) => {
          this.applications[index] = { sn: ++index, ...leave };
        });
        this.applications.forEach((application) => {
          officers.forEach((officer) => {
            if (
              application.leapp_id === parseFloat(officer.auth_travelapp_id)
            ) {

              let names = null;
              if(application.leapp_status === 0){
                if(officer.auth_status === 0){
                  names = `${officer.officers.emp_first_name} ${officer.officers.emp_last_name} (${officer.officers.emp_unique_id}), `;
                  application["Officer"] += names;
                }
              }else{
                names = `${officer.officers.emp_first_name} ${officer.officers.emp_last_name} (${officer.officers.emp_unique_id}), `;
                application["Officer"] = 'undefined'+names;
              }

            }
          });
        });
        this.totalRows = this.applications.length;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      this.leaveAppID = row.leapp_id;
      this.$router.push({
        name: "view-leave-authorization",
        params: { leaveAppID: this.leaveAppID },
      });
    },
  },
  data() {
    return {
      title: "Leave Authorizations",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Leave Authorizations",
          active: true,
        },
      ],
      applications: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "employee", label: "Employee", sortable: true },
        {
          key: "LeaveType",
          label: "Leave Type",
          sortable: true,
        },
        { key: "leapp_start_date", label: "Start Date", sortable: true },
        { key: "leapp_end_date", label: "End Date", sortable: true },
        { key: "leapp_total_days", label: "Leave Length", sortable: true },
        { key: "Officer", label: "Current Desk", sortable: true },
        {
          key: "leapp_status",
          label: "Application Status",
          sortable: true,
        },
      ],
      leaveAppID: null,
      currentDeskHandle:null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <p><strong>Note:</strong> These filters relates to your actions. Leave applications pending, approved or declined by you.</p>
            <div class="btn-group">
              <button v-on:click="refreshTableByStatus(0)" class="btn btn-warning btn-sm">Pending</button>
              <button v-on:click="refreshTableByStatus(1)" class="btn btn-success btn-sm">Approved</button>
              <button v-on:click="refreshTableByStatus(2)" class="btn btn-danger btn-sm">Declined</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="applications"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                @row-selected="selectRow"
              >
                <template #cell(employee)="row">
                  <p class="mb-0">
                    {{ row.value.emp_first_name }} {{ row.value.emp_last_name }}
                  </p>
                  <small class="text-muted">
                    {{ row.value.emp_unique_id }}
                  </small>
                </template>
                <template #cell(LeaveType)="row">
                  <p class="mb-0">
                    {{ row.value.leave_name }}
                  </p>
                </template>
                <template #cell(leapp_start_date)="row">
                  <span> {{ new Date(row.value).toDateString() }}</span>
                </template>
                <template #cell(leapp_end_date)="row">
                  <span> {{ new Date(row.value).toDateString() }}</span>
                </template>
                <template #cell(leapp_total_days)="row">
                  <span> {{ row.value }} days</span>
                </template>
                <template #cell(Officer)="row">
                  <p class="mb-0">
                    {{ row.value.substring(9) }}
                  </p>
                  <small class="text-muted">
                    {{ row.value.emp_unique_id }}
                  </small>
                </template>
                <template #cell(leapp_status)="row">
                  <span
                    v-if="row.value === 0"
                    class="badge badge-pill badge-warning"
                  >
                    pending
                  </span>
                  <span
                    v-else-if="row.value === 1"
                    class="badge badge-pill badge-success"
                  >
                    approved
                  </span>
                  <span
                    v-else-if="row.value === 2"
                    class="badge badge-pill badge-danger"
                  >
                    declined
                  </span>
                  <span
                    v-else-if="row.value === 3"
                    class="badge badge-pill badge-danger"
                  >
                    active
                  </span>
                  <span
                    v-else-if="row.value === 4"
                    class="badge badge-pill badge-danger"
                  >
                    finished
                  </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
